import * as React from "react";
import Layout from "../components/main/layout/Layout";
import Section from "../components/main/site/main/Section";
import Contact from "../components/main/site/form/Contact";
import { Typography, Grid } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import TechStack from "../components/sites/index/TechStack";
const test = () => {
  return (
    <Layout>
      <Section>
        <Typography component={"h1"} sx={{typography: ['h6','h3']}}>Unsere Leistung für Sie <br/>Komponentenbasierte Softwareentwickling</Typography>
        <ul>
          <li>
            Individuelle SaaS/BaaS Plattformen (CMS, ERP, CRM, Data Warehouse,
            uvm.)
          </li>
          <li>Webseiten und Online-Shops</li>
          <li>App Entwicklung</li>
          <li>Software Beratung, Entwicklung und Betrieb</li>
        </ul>
        <Grid container spacing={4}>
          <Grid item sm={4}>
            <Typography variant="h5" component="p">
              Was sind SaaS-Plattformen?
            </Typography>
            <Avatar sx={{backgroundColor:'inherit',color:'black',border:'1px solid black',padding:'90px'
              ,mx:'auto',my:'16px'}}><CloudDownloadOutlinedIcon sx={{fontSize:'70px'}}/></Avatar>
            <Typography variant="body1">
              SaaS (Software as a Service) ist die Umschreibung für eine
              Vielzahl cloudbasierter, skalierender Software-Systeme. <br/> Diese können auf Ihren
              eigenen Servern laufen und Sie haben die volle datenschutzkonforme Kontrolle über Ihre
              Daten. Wenn Sie keine Server haben, dann können wir Ihnen mit
              unseren Partnern eine sichere und günstige Infrastruktur anbieten.
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="h5" component="p">
              Welche Apps könnt Ihr erstellen?
            </Typography>
            <Avatar sx={{backgroundColor:'inherit',color:'black',border:'1px solid black',padding:'90px'
              ,mx:'auto',my:'16px'}}><PhoneIphoneIcon sx={{fontSize:'70px'}}/></Avatar>
            <Typography variant="body1">
              In der Regel erstteln wir Progressive Web Apps (PWA's).<br/>Diese haben den
              Vorteil, dass Sie nur einmal entwickelt werden müssen und auf
              allen gängigen mobilen Endgeräten laufen.
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="h5" component="p">
              Was gibt es im Bereich E-Commerce?
            </Typography>
            <Avatar sx={{backgroundColor:'inherit',color:'black',border:'1px solid black',padding:'90px',mx:'auto'
              ,my:'16px'}}><ShoppingCartOutlinedIcon sx={{fontSize:'70px'}}/></Avatar>
            <Typography>
              Im E-Commerce bieten wir für Sie hochwertige SEO optimierte Shops und Webseiten an
            </Typography>
          </Grid>
        </Grid>
      </Section>
      <Contact url= {'https://app.businesshamster.de:3000/contact'} />
      <TechStack/>
    </Layout>
  );
};
export default test;
