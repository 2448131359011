import * as React from "react";
import { Box, Typography } from "@mui/material";
import Section from "../../main/site/main/Section";
import Android from "../../../images/techstack/android-icon.svg";
import Angular from "../../../images/techstack/angular.svg";
import Apple from "../../../images/techstack/apple-app-store.svg";
import AWS from "../../../images/techstack/aws.svg";
import Azure from "../../../images/techstack/azure.svg";
import ES6 from "../../../images/techstack/es6.svg";
import Figma from "../../../images/techstack/figma.svg";
import Go from "../../../images/techstack/go.svg";
import GoogleCloud from "../../../images/techstack/google-cloud.svg";
import GooglePlay from "../../../images/techstack/google-play.svg";
import IOS from "../../../images/techstack/ios.svg";
import JavaScript from "../../../images/techstack/javascript.svg";
import Microsoft from "../../../images/techstack/microsoft.svg";
import NodeJs from "../../../images/techstack/nodejs.svg";
import ReactSvg from "../../../images/techstack/react.svg";
import Vue from "../../../images/techstack/vue.svg";
import Wordpress from "../../../images/techstack/wordpress-icon.svg";

const mainStack = [
  { name: "Android", svg: Android },
  { name: "Angular", svg: Angular },
  { name: "Apple", svg: Apple },
  { name: "AWS", svg: AWS },
  { name: "Azure", svg: Azure },
  { name: "ES6", svg: ES6 },
  { name: "Figma", svg: Figma },
  { name: "Go", svg: Go },
  { name: "Google Cloud", svg: GoogleCloud },
  { name: "Google Play", svg: GooglePlay },
  { name: "IOS", svg: IOS },
  { name: "JavaScript", svg: JavaScript },
  { name: "Microsoft", svg: Microsoft },
  { name: "NodeJs", svg: NodeJs },
  { name: "React", svg: ReactSvg },
  { name: "Vue", svg: Vue },
  { name: "Wordpress", svg: Wordpress },
];
const TechStack = () => {
  return (
    <Section>
        <Typography  sx={{mb:2}} variant="h4" component="h2">Unser Technologie Stack</Typography>
      {mainStack.map((stack,id) => (
        <Box
          key={id}
          component="img"
          sx={{maxWidth:"120px",maxHeight:"75px", m:2
          }}
          alt={stack.name}
          src={stack.svg}
        />
      ))}
    </Section>
  );
};
export default TechStack;
